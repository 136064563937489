@import 'mixins.scss';

.air__menuLeft {
  flex: 0 0 256px;
  max-width: 256px;
  min-width: 256px;
  width: 256px;
  min-height: 100%;
  background: $dark-gray-6;
  overflow: hidden;

  @media (max-width: $sm-max-width) {
    position: fixed;
    z-index: 950;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: visible;
    margin-left: -256px;
    transition: transform 0.2s ease-in-out;

    &__mobileToggleButton {
      display: block !important;
    }

    &__toggleButton {
      display: none;
    }

    @at-root {
      .air__menuLeft__mobileToggled {
        transform: translateX(256px);
      }
    }
  }

  // fixed container
  &__outer {
    position: fixed;
    z-index: 950;
    top: 0;
    height: 100%;
    flex: 0 0 256px;
    max-width: 256px;
    min-width: 256px;
    width: 256px;
    display: flex;
    flex-direction: column;
  }

  // menu container
  &__container {
    padding-bottom: rem(20);
  }

  &__backdrop {
    position: fixed;
    z-index: 945;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $black;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    @at-root {
      @media (max-width: $sm-max-width) {
        .air__menuLeft__mobileToggled + & {
          opacity: 0.2;
          visibility: visible;
        }
      }
    }
  }

  // mobile toggle button
  &__mobileToggleButton {
    position: absolute;
    right: rem(-40);
    top: rem(142);
    width: rem(40);
    height: rem(40);
    background: $black;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    display: none;
    text-align: center;
    box-shadow: $shadow-2;

    span {
      display: inline-block;
      width: 14px;
      height: 2px;
      position: relative;
      background: $white;
      transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      top: 5px;

      @at-root {
        .air__menuLeft__mobileToggled & {
          background: transparent;
          &:before {
            transform: translateY(5px) rotate(45deg);
          }
          &:after {
            transform: translateY(-5px) rotate(-45deg);
          }
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        background: $white;
        width: 100%;
        height: 2px;
        transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
      &:before {
        top: -5px;
      }
      &:after {
        top: 5px;
      }
    }
  }

  // toggle button
  &__toggleButton {
    @at-root {
      .air__menuLeft__toggled {
        .air__menuLeft__toggleButton {
          transform: rotate(180deg);
        }
      }
    }
    position: absolute;
    top: rem(12);
    right: rem(10);
    z-index: 2;
    width: 40px;
    height: 40px;
    cursor: pointer;
    &:hover {
      span {
        opacity: 1 !important;
      }
    }
    span {
      @include transition-slow();
      opacity: 0.9;
      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 12px;
        height: 3px;
        background: $primary;
        border-radius: 5px;
      }
      &:before {
        top: 14px;
        left: 6px;
        transform: rotate(-45deg);
      }
      &:after {
        top: 21px;
        left: 6px;
        transform: rotate(45deg);
      }
      &:first-child {
        position: relative;
        left: 3px;
      }
      &:last-child {
        position: relative;
        left: 11px;
        opacity: 0.5;
      }
    }
  }

  //logo
  &__logo {
    display: block;
    padding: rem(15) rem(20);
    line-height: 1;
    height: rem(64);
    overflow: hidden;

    &__letter {
      float: left;

      svg {
        margin-right: rem(6);
        height: rem(30);
        width: rem(30);
        path:nth-child(1) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
        }
        path:nth-child(2) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
          animation-delay: 2s;
        }
        path:nth-child(3) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
          animation-delay: 4s;
        }

        @keyframes logoAnimate {
          0% {
            stroke: $primary;
            fill: $primary;
          }
          50% {
            stroke: #1ad697;
            fill: #1ad697;
          }
          100% {
            stroke: $primary;
            fill: $primary;
          }
        }
      }
    }

    &__name {
      font-weight: 700;
      color: $white;
      font-size: rem(18);
      margin-left: rem(40);
    }
    &__descr {
      color: $gray-4;
      margin-left: rem(40);
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  // user
  &__user {
    display: block;
    padding: rem(15) rem(20);
    height: rem(72);
    line-height: 1.3;
    &__avatar {
      float: left;
      background: $dark-gray-4 !important;
    }
    &__name {
      margin-left: rem(55);
      color: $white;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: rem(2);
    }
    &__role {
      margin-left: rem(55);
      color: $dark-gray-2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  // banner
  &__banner {
    margin: rem(40) rem(20) rem(20);
    background: $primary;
    background: linear-gradient(45deg, $primary 0%, $primary 100%);
    border-radius: 12px;
    padding: rem(20);
    color: $white;
  }

  // menu items
  &__list {
    list-style: none;
    padding-left: 0;
    margin: 0;

    .air__menuLeft__list {
      .air__menuLeft__link {
        padding-left: rem(49);
      }
    }
  }

  &__category {
    text-transform: uppercase;
    color: $dark-gray-2;
    letter-spacing: 1px;
    font-size: rem(12);
    padding: rem(14) rem(20);
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: rem(20);
      right: rem(20);
      height: 1px;
      background: darken($text, 20%);
      display: none;
    }
  }

  &__item {
    display: block;
    &__active {
      > .air__menuLeft__link {
        background: $primary;
        color: $white;

        .air__menuLeft__icon {
          color: $white;
        }
      }
    }
  }

  &__submenu {
    > .air__menuLeft__link {
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: rem(20);
        right: rem(20);
        width: 6px;
        height: 2px;
        background: $gray-6;
        transform: rotate(-45deg);
        @include transition-bg();
      }
      &:after {
        right: rem(24);
        transform: rotate(45deg);
      }
    }
    &__active {
      background: darken($black, 4%);
      > .air__menuLeft__link {
        color: $white;
        &:before,
        &:after {
          background: $white;
        }

        .air__menuLeft__icon {
          color: $white;
        }
      }
      > .air__menuLeft__list {
        display: block !important;
      }
    }
    .air__menuLeft__list {
      display: none;
      padding-bottom: rem(10);
    }
  }

  &__link {
    color: $gray-5;
    display: block;
    padding: rem(9) rem(40) rem(9) rem(20);
    font-size: rem(15);

    &:hover,
    &:focus {
      color: $white;

      &:before,
      &:after {
        background: $white;
      }
    }
  }

  &__icon {
    display: inline-block;
    width: rem(25);
  }

  // toggled menu
  @at-root {
    @media (min-width: $sm-min-width) {
      .air__menuLeft__toggled:not(.air__menuLeft__compact) {
        flex: 0 0 80px !important;
        max-width: 80px !important;
        min-width: 80px !important;
        width: 80px !important;

        .air__menuLeft {
          &__outer {
            flex: 0 0 80px !important;
            max-width: 80px !important;
            min-width: 80px !important;
            width: 80px !important;
          }
          &__logo {
            visibility: hidden;
          }
          &__toggleButton {
            right: rem(20);
          }
          &__user {
            &__name,
            &__role {
              display: none;
            }
          }
          &__banner {
            display: none;
          }
          &__submenu {
            &__active {
              background: $primary;
            }
            > .air__menuLeft__list {
              display: none !important;
            }
          }
          &__link {
            > span {
              display: none;
            }
            &:before {
              right: rem(18);
              top: rem(21);
            }
            &:after {
              right: rem(18);
              top: rem(18);
            }
          }
          &__icon {
            width: rem(40);
            text-align: center;
          }
          &__category {
            text-indent: -999px;
            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  // compact menu
  @at-root {
    @media (min-width: $sm-min-width) {
      .air__menuLeft__compact {
        flex: 0 0 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
        width: 150px !important;

        .air__menuLeft {
          &__outer {
            flex: 0 0 150px !important;
            max-width: 150px !important;
            min-width: 150px !important;
            width: 150px !important;
          }

          &__logo {
            &__letter {
              text-align: center;
              float: none;
              margin: 0 auto;
              svg {
                margin: 0;
              }
            }
            &__name {
              display: none;
            }
            &__descr {
              display: none;
            }
          }
          &__toggleButton {
            display: none;
          }
          &__user {
            height: auto;
            text-align: center;
            &__avatar {
              float: none;
              margin-bottom: rem(10);
            }
            &__name,
            &__role {
              display: block;
              margin-left: 0;
            }
          }
          &__banner {
            display: none;
          }
          &__submenu {
            &__active {
              background: $primary;
            }
            > .air__menuLeft__list {
              display: none !important;
            }
          }
          &__link {
            text-align: center;
            padding: rem(9) rem(20);
            > span {
              display: block;
              float: none !important;
              &:global(.badge) {
                display: inline-block;
              }
            }
            &:before {
              right: rem(18);
              top: 50%;
              margin-top: 2px;
            }
            &:after {
              right: rem(18);
              top: 50%;
              margin-top: -1px;
            }
          }
          &__icon {
            width: rem(40);
            display: block;
            margin: 0 auto rem(5);
            text-align: center;
          }
          &__category {
            display: none;
          }
        }
      }
    }
  }

  // unfixed
  @at-root {
    .air__menuLeft__unfixed {
      display: flex;

      .air__menuLeft__outer {
        height: auto;
        position: static;
      }
    }
  }

  // menu shadow
  @at-root {
    .air__menuLeft__shadow {
      box-shadow: $shadow-3;
    }
  }

  // menu themes
  @at-root {
    // gray theme
    .air__menuLeft__gray {
      background: $gray-1;

      .air__menuLeft {
        &__category {
          color: $gray-5;

          &:after {
            background: darken($gray-1, 4%);
          }
        }

        &__link {
          color: $text;

          &:hover {
            color: $white;
            background: $primary;
            &:before,
            &:after {
              background: $white !important;
            }
          }
        }

        &__item {
          &__active {
            .air__menuLeft__link {
              color: $white;
            }
          }
        }

        &__submenu {
          &__active {
            background: $gray-2;
            > .air__menuLeft__link {
              .air__menuLeft__icon {
                color: $text;
              }

              &:hover {
                .air__menuLeft__icon {
                  color: $white;
                }
              }
            }

            @at-root {
              .air__menuLeft__toggled.air__menuLeft__gray {
                .air__menuLeft__submenu__active {
                  background: $gray-2 !important;
                }
              }
            }
          }

          > .air__menuLeft__link {
            &:before,
            &:after {
              background: $gray-5;
            }
          }
        }

        &__user__name {
          color: $text;
        }

        &__user__role {
          color: $gray-5;
        }

        &__user__avatar {
          background: $gray-2 !important;
        }

        &__logo {
          &__letter {
            color: $black;
            border-color: $black;
          }
          &__name {
            color: $black;
          }
        }
      }
    }

    // white theme
    .air__menuLeft__white {
      background: $white;

      .air__menuLeft {
        &__category {
          color: $gray-5;

          &:after {
            background: $gray-1;
          }
        }

        &__link {
          color: $text;

          &:hover {
            color: $white;
            background: $primary;
            &:before,
            &:after {
              background: $white !important;
            }
          }
        }

        &__item {
          &__active {
            .air__menuLeft__link {
              color: $white;
            }
          }
        }

        &__submenu {
          &__active {
            background: $gray-1;
            > .air__menuLeft__link {
              .air__menuLeft__icon {
                color: $text;
              }

              &:hover {
                .air__menuLeft__icon {
                  color: $white;
                }
              }
            }

            @at-root {
              .air__menuLeft__toggled.air__menuLeft__white {
                .air__menuLeft__submenu__active {
                  background: $gray-1 !important;
                }
              }
            }
          }

          > .air__menuLeft__link {
            &:before,
            &:after {
              background: $gray-5;
            }
          }
        }

        &__user__name {
          color: $text;
        }

        &__user__role {
          color: $gray-5;
        }

        &__user__avatar {
          background: $gray-2 !important;
        }

        &__logo {
          &__letter {
            color: $black;
            border-color: $black;
          }
          &__name {
            color: $black;
          }
        }
      }
    }
  }

  // flyout menu styles
  @media (min-width: $sm-min-width) {
    &__flyout:not(.air__menuLeft__toggled) {
      .air__menuLeft {
        &__submenu {
          &__active {
            background: $primary;
            > .air__menuLeft__link {
              color: $white;
              .air__menuLeft__icon {
                color: $white;
              }
            }
          }
          > .air__menuLeft__list {
            display: none !important;
          }
        }
        &__link {
          &:before {
            right: rem(18);
            top: 50%;
          }
          &:after {
            right: rem(18);
            top: 50%;
            margin-top: -3px;
          }
        }
      }
    }

    // menu item hover styles
    &__flyout,
    &__compact,
    &__toggled {
      // default
      .air__menuLeft {
        &__link {
          &:hover {
            background: $primary;
          }
        }
      }

      &.air__menuFlyout {
        // black
        &__black {
          .air__menuLeft {
            &__link {
              &:hover {
                background: $black;
              }
            }
          }
        }
        // gray
        &__gray {
          .air__menuLeft {
            &__link {
              &:hover {
                background: $gray-1;
                color: $primary;
                &:before,
                &:after {
                  background: $primary;
                }
              }
            }
          }
        }
        // white
        &__white {
          .air__menuLeft {
            &__link {
              &:hover {
                background: $white;
                color: $primary;
                &:before,
                &:after {
                  background: $primary;
                }
              }
            }
          }
        }
      }
    }

    // flyout container
    @at-root {
      .air__menuFlyout {
        position: fixed;
        z-index: 3000;
        top: 100px;
        box-shadow: $shadow-5;
        background: $primary;
        transform: translate3d(0, calc(-50% + 40px), 0);
        transition: transform 0.1s ease-in-out;
        transform-origin: 0 0;

        @at-root {
          .air__menuFlyoutLeft {
            &:global(.air__menuFlyout__animation-enter-done) {
              transform: translate3d(0, calc(-50% + 20px), 0);
            }
          }
        }

        .air__menuLeft {
          &__list {
            max-width: rem(540);
            display: block !important;
            height: auto !important;
          }

          &__item {
            float: left;
            width: rem(180);
            border-left: 1px solid rgba($gray-1, 0.1);
            border-bottom: 1px solid rgba($gray-1, 0.1);
            &:nth-child(3n + 1) {
              border-left: none;
            }

            &__active {
              .air__menuLeft__link {
                background: rgba($gray-1, 0.1);
              }
            }
          }

          &__link {
            color: $white;
            padding: rem(15) rem(30);

            &:hover {
              background: rgba($gray-1, 0.1);
            }

            > span {
              display: block !important;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        // flyout themes
        @at-root {
          @media (min-width: $sm-min-width) {
            .air__menuFlyout {
              // black
              &__black {
                &.air__menuFlyout {
                  background: $black;
                }
              }
              //white
              &__white {
                .air__menuLeft {
                  &__link {
                    &:hover {
                      &:before,
                      &:after {
                        background: $primary !important;
                      }
                    }
                  }
                  &__submenu {
                    &__active {
                      > .air__menuLeft__link {
                        &:hover {
                          color: $primary;
                          .air__menuLeft__icon {
                            color: $primary;
                          }
                        }
                      }
                    }
                  }
                }
                &.air__menuFlyout {
                  background: $white;
                  box-shadow: $shadow-2;

                  .air__menuLeft {
                    &__item {
                      border-left: 1px solid $gray-1;
                      border-bottom: 1px solid $gray-1;
                    }
                    &__link {
                      color: $primary;
                      &:hover {
                        color: $primary;
                        background: rgba($gray-3, 0.3);
                        &__active {
                          .air__menuLeft__link {
                            background: rgba($gray-3, 0.3);
                          }
                        }
                      }
                    }
                  }
                }
              }
              // gray
              &__gray {
                .air__menuLeft {
                  &__link {
                    &:hover {
                      &:before,
                      &:after {
                        background: $primary !important;
                      }
                    }
                  }
                  &__submenu {
                    &__active {
                      > .air__menuLeft__link {
                        &:hover {
                          color: $primary;
                          .air__menuLeft__icon {
                            color: $primary;
                          }
                        }
                      }
                    }
                  }
                }
                &.air__menuFlyout {
                  background: $gray-1;
                  box-shadow: $shadow-2;

                  .air__menuLeft {
                    &__item {
                      border-left: 1px solid darken($gray-1, 4%);
                      border-bottom: 1px solid darken($gray-1, 4%);
                      &__active {
                        .air__menuLeft__link {
                          background: rgba($gray-3, 0.3);
                        }
                      }
                    }
                    &__link {
                      color: $primary;
                      &:hover {
                        color: $primary;
                        background: rgba($gray-3, 0.3);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
