@import 'mixins.scss';

.air__menuTop {
  background: $dark-gray-6;
  border-bottom: 1px solid $border;

  @media (max-width: $sm-max-width) {
    position: fixed;
    z-index: 950;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: visible;
    flex: 0 0 256px;
    max-width: 256px;
    min-width: 256px;
    width: 256px;
    margin-left: -256px;
    transition: transform 0.2s ease-in-out;

    &__mobileToggleButton {
      display: block !important;
    }

    @at-root {
      .air__menuTop__mobileToggled {
        transform: translateX(256px);
      }
    }

    &__outer {
      @media (max-width: $sm-max-width) {
        position: fixed;
        z-index: 950;
        top: 0;
        height: 100%;
        flex: 0 0 256px;
        max-width: 256px;
        min-width: 256px;
        width: 256px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__outer {
    position: static;
    display: flex;
    flex-direction: row;

    @media (max-width: $sm-max-width) {
      flex-direction: column;
    }
  }

  // menu container
  &__container {
    padding-top: rem(15);
    padding-bottom: rem(15);

    @media (max-width: $sm-max-width) {
      overflow: auto;
      padding-top: 0;
      padding-bottom: rem(20);
    }
  }

  &__backdrop {
    position: fixed;
    z-index: 945;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $black;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    @at-root {
      @media (max-width: $sm-max-width) {
        .air__menuTop__mobileToggled + & {
          opacity: 0.2;
          visibility: visible;
        }
      }
    }
  }

  // mobile toggle button
  &__mobileToggleButton {
    position: absolute;
    right: rem(-40);
    top: rem(142);
    width: rem(40);
    height: rem(40);
    background: $black;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    display: none;
    text-align: center;
    box-shadow: $shadow-2;

    span {
      display: inline-block;
      width: 14px;
      height: 2px;
      position: relative;
      background: $white;
      transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      top: 5px;

      @at-root {
        .air__menuTop__mobileToggled & {
          background: transparent;
          &:before {
            transform: translateY(5px) rotate(45deg);
          }
          &:after {
            transform: translateY(-5px) rotate(-45deg);
          }
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        background: $white;
        width: 100%;
        height: 2px;
        transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
      &:before {
        top: -5px;
      }
      &:after {
        top: 5px;
      }
    }
  }

  //logo
  &__logo {
    display: block;
    padding: rem(15) rem(20);
    line-height: 1;
    height: rem(64);
    white-space: nowrap;
    max-width: rem(250);

    &__letter {
      float: left;

      svg {
        margin-right: rem(6);
        height: rem(30);
        width: rem(30);
        path:nth-child(1) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
        }
        path:nth-child(2) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
          animation-delay: 2s;
        }
        path:nth-child(3) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
          animation-delay: 4s;
        }

        @keyframes logoAnimate {
          0% {
            stroke: $primary;
            fill: $primary;
          }
          50% {
            stroke: #1ad697;
            fill: #1ad697;
          }
          100% {
            stroke: $primary;
            fill: $primary;
          }
        }
      }
    }
    &__name {
      font-weight: 700;
      color: $white;
      font-size: rem(18);
      margin-left: rem(40);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__descr {
      color: $gray-4;
      margin-left: rem(40);
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      max-width: calc(100% - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // menu items
  &__list {
    list-style: none;
    padding-left: 0;
    margin: 0;

    .air__menuTop__list {
      .air__menuTop__link {
        padding-left: rem(49);
      }
    }

    @media (min-width: $sm-min-width) {
      display: flex;
      flex-wrap: wrap;
      .air__menuTop__list {
        display: none !important;
      }
    }
  }

  &__item {
    :global(.badge) {
      margin-left: rem(10);
    }
    &__active {
      > .air__menuTop__link {
        background: $primary;
        color: $white;

        .air__menuTop__icon {
          color: $white;
        }
      }
    }
  }

  &__submenu {
    > .air__menuTop__link {
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: rem(20);
        right: rem(20);
        width: 6px;
        height: 2px;
        background: $gray-6;
        transform: rotate(-45deg);
        @include transition-bg();
      }
      &:after {
        right: rem(24);
        transform: rotate(45deg);
      }

      @media (min-width: $sm-min-width) {
        padding: rem(6) rem(35) rem(5) rem(15);

        &:before,
        &:after {
          top: rem(18);
          right: rem(15);
        }
        &:after {
          right: rem(19);
        }
      }
    }
    &__active {
      background: darken($black, 4%);
      @media (min-width: $sm-min-width) {
        border-radius: 3px;
      }

      > .air__menuTop__link {
        color: $white;
        &:before,
        &:after {
          background: $white;
        }

        .air__menuTop__icon {
          color: $white;
        }
      }

      > .air__menuTop__list {
        display: block !important;
      }
    }
    .air__menuTop__list {
      display: none;
      padding-bottom: rem(10);

      @media (min-width: $sm-min-width) {
        display: none !important;
      }
    }
  }

  &__link {
    color: $gray-5;
    display: block;
    padding: rem(9) rem(40) rem(9) rem(20);
    font-size: rem(15);

    @media (min-width: $sm-min-width) {
      padding: rem(6) rem(20) rem(5) rem(15);
      border-radius: 3px;
    }

    &:hover,
    &:focus {
      color: $white !important;

      &:before,
      &:after {
        background: $white;
      }
    }
  }

  &__icon {
    display: inline-block;
    width: rem(25);
  }

  // menu themes
  @at-root {
    // gray theme
    .air__menuTop__gray {
      background: $gray-1;

      .air__menuTop {
        &__link {
          color: $text;

          &:hover {
            color: $white;
            background: $primary;
            &:before,
            &:after {
              background: $white !important;
            }
          }
        }

        &__item {
          &__active {
            .air__menuTop__link {
              color: $white;
            }
          }
        }

        &__submenu {
          &__active {
            background: $gray-2;
            > .air__menuTop__link {
              .air__menuTop__icon {
                color: $text;
              }

              &:hover {
                .air__menuTop__icon {
                  color: $white;
                }
              }
            }
          }

          > .air__menuTop__link {
            &:before,
            &:after {
              background: $gray-5;
            }
          }
        }

        &__logo {
          &__letter {
            color: $black;
            border-color: $black;
          }
          &__name {
            color: $black;
          }
        }
      }
    }

    // white theme
    .air__menuTop__white {
      background: $white;

      .air__menuTop {
        &__link {
          color: $text;

          &:hover {
            color: $white;
            background: $primary;
            &:before,
            &:after {
              background: $white !important;
            }
          }
        }

        &__item {
          &__active {
            .air__menuTop__link {
              color: $white;
            }
          }
        }

        &__submenu {
          &__active {
            background: $gray-1;
            > .air__menuTop__link {
              .air__menuTop__icon {
                color: $text;
              }

              &:hover {
                .air__menuTop__icon {
                  color: $white;
                }
              }
            }
          }

          > .air__menuTop__link {
            &:before,
            &:after {
              background: $gray-5;
            }
          }
        }

        &__logo {
          &__letter {
            color: $black;
            border-color: $black;
          }
          &__name {
            color: $black;
          }
        }
      }
    }
  }

  // flyout menu styles
  @media (min-width: $sm-min-width) {
    &__flyout:not(.air__menuTop__toggled) {
      .air__menuTop {
        &__submenu {
          &__active {
            background: $primary;
            > .air__menuTop__link {
              color: $white;
              .air__menuTop__icon {
                color: $white;
              }
            }
          }
        }
      }
    }

    // default
    .air__menuTop {
      &__link {
        &:hover {
          background: $primary;
        }
      }
    }

    // menu item hover styles
    &.air__menuFlyout {
      // black
      &__black {
        .air__menuTop {
          &__link {
            &:hover {
              background: $black;
            }
          }
        }
      }
      // gray
      &__gray {
        .air__menuTop {
          &__link {
            &:hover {
              background: $gray-1;
              color: $primary;
              &:before,
              &:after {
                background: $primary;
              }
            }
          }
        }
      }
      // white
      &__white {
        .air__menuTop {
          &__link {
            &:hover {
              background: $white;
              color: $primary;
              &:before,
              &:after {
                background: $primary;
              }
            }
          }
        }
      }
    }

    // flyout container
    @at-root {
      .air__menuFlyout {
        position: fixed;
        z-index: 3000;
        top: 100px;
        box-shadow: $shadow-5;
        background: $primary;
        transform: translate3d(-50%, 20px, 0);
        transition: transform 0.1s ease-in-out;
        transform-origin: 0 0;

        @at-root {
          .air__menuFlyoutTop {
            &:global(.air__menuFlyout__animation-enter-done) {
              transform: translate3d(-50%, 0, 0);
            }
          }
        }

        .air__menuTop {
          &__list {
            max-width: rem(360);
            display: block !important;
            height: auto !important;
          }

          &__item {
            float: left;
            width: rem(180);
            border-left: 1px solid rgba($gray-1, 0.1);
            border-bottom: 1px solid rgba($gray-1, 0.1);
            &:nth-child(3n + 1) {
              border-left: none;
            }

            &__active {
              .air__menuTop__link {
                background: rgba($gray-1, 0.1);
              }
            }
          }

          &__link {
            color: $white;
            padding: rem(15) rem(30);

            &:hover {
              background: rgba($gray-1, 0.1);
            }

            > span {
              display: block !important;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        // flyout themes
        @at-root {
          @media (min-width: $sm-min-width) {
            .air__menuFlyout {
              // black
              &__black {
                &.air__menuFlyout {
                  background: $black;
                }
              }
              //white
              &__white {
                .air__menuTop {
                  &__link {
                    &:hover {
                      color: $primary !important;
                      &:before,
                      &:after {
                        background: $primary !important;
                      }
                      .air__menuTop__icon {
                        color: $primary;
                      }
                    }
                  }
                  &__submenu {
                    &__active {
                      > .air__menuTop__link {
                        &:hover {
                          color: $primary;
                          .air__menuTop__icon {
                            color: $primary;
                          }
                        }
                      }
                    }
                  }
                }
                &.air__menuFlyout {
                  background: $white;
                  box-shadow: $shadow-2;

                  .air__menuTop {
                    &__item {
                      border-left: 1px solid $gray-1;
                      border-bottom: 1px solid $gray-1;
                    }
                    &__link {
                      color: $primary;
                      &:hover {
                        color: $primary;
                        background: rgba($gray-3, 0.3);
                        &__active {
                          .air__menuTop__link {
                            background: rgba($gray-3, 0.3);
                          }
                        }
                      }
                    }
                  }
                }
              }
              // gray
              &__gray {
                .air__menuTop {
                  &__link {
                    &:hover {
                      color: $primary !important;
                      &:before,
                      &:after {
                        background: $primary !important;
                      }
                      .air__menuTop__icon {
                        color: $primary;
                      }
                    }
                  }
                  &__submenu {
                    &__active {
                      > .air__menuTop__link {
                        &:hover {
                          color: $primary;
                          .air__menuTop__icon {
                            color: $primary;
                          }
                        }
                      }
                    }
                  }
                }
                &.air__menuFlyout {
                  background: $gray-1;
                  box-shadow: $shadow-2;

                  .air__menuTop {
                    &__item {
                      border-left: 1px solid darken($gray-1, 4%);
                      border-bottom: 1px solid darken($gray-1, 4%);
                      &__active {
                        .air__menuTop__link {
                          background: rgba($gray-3, 0.3);
                        }
                      }
                    }
                    &__link {
                      color: $primary;
                      &:hover {
                        color: $primary;
                        background: rgba($gray-3, 0.3);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// dark theme
[data-vb-theme='dark'] {
  .air__menuTop {
    border-color: $dark-gray-6;
  }
}
